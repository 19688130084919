<template>
    <div>
        <logo-bar />
        <div v-if="loading">
            <v-overlay>
                <v-progress-circular
                    :size="70"
                    :width="7"
                    indeterminate
                />
            </v-overlay>
        </div>
        <v-container v-else>
            <v-row class="fill-height d-print-none">
                <v-col>
                    <v-overlay :value="loading">
                        <v-progress-circular
                            :size="70"
                            :width="7"
                            indeterminate
                        />
                    </v-overlay>
                    <div v-if="!loading && groups.length">
                        <v-row class="mb-4">
                            <v-col cols="4">
                                <v-btn
                                    large
                                    color="success"
                                    to="/"
                                >
                                    <v-icon left>mdi-home</v-icon> Home
                                </v-btn>

                                <v-btn
                                    large
                                    color="warning"
                                    @click="$refs.help.startTour()"
                                >
                                    <v-icon left>mdi-help-circle</v-icon> Help
                                </v-btn>
                                <br />
                                <v-switch
                                    v-model="gameColors"
                                    label="Sport Colors"
                                    color="success"
                                />
                                <!-- Reget Games -->
                                <v-btn
                                    large
                                    color="success"
                                    @click="updateGames"
                                >
                                    <v-icon left>mdi-refresh</v-icon> Refresh Games
                                </v-btn>
                            </v-col>
                            <v-spacer />
                            <v-col cols="auto">
                                <v-btn
                                    large
                                    color="success"
                                    @click="addGroupDialog = true"
                                >
                                    <v-icon left>mdi-plus</v-icon> Create New Location
                                </v-btn>
                            </v-col>
                            <v-spacer />

                            <v-col
                                cols="4"
                                id="help_columns"
                                class="text-right"
                            >
                                <v-btn
                                    large
                                    color="success"
                                    @click="embedModal = true"
                                >
                                    <v-icon left>mdi-cog</v-icon> Configure Embed
                                </v-btn>

                                <v-slider
                                    class="pt-6"
                                    label="Columns"
                                    :thumb-size="24"
                                    thumb-label="always"
                                    v-model="calcols"
                                    hide-details
                                    max="5"
                                    step="1"
                                    min="1"
                                />

                            </v-col>

                        </v-row>
                        <v-card
                            class="blue mb-5"
                            v-for="(group, group_index) in groups"
                            v-bind:key="group.id"
                        >
                            <v-row v-if="group.screens && group.screens.length">
                                <v-col>
                                    <v-sheet height="64">
                                        <v-row
                                            id="help_date"
                                            class="blue ma-0"
                                            align="center"
                                            justify="space-between"
                                        >
                                            <v-col cols="2">
                                                <v-btn
                                                    dark
                                                    outlined
                                                    class="mr-4"
                                                    @click="setToday"
                                                    v-if="!isTodaysDate"
                                                >
                                                    Go to Today
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-btn
                                                    fab
                                                    small
                                                    color="primary"
                                                    class="mr-3"
                                                    v-if="!isTodaysDate"
                                                    @click="prev"
                                                >
                                                    <v-icon small> mdi-chevron-left </v-icon>
                                                </v-btn>
                                                <span class="text-h5 white--text">
                                                    {{ currentDate }}
                                                </span>
                                                <v-btn
                                                    fab
                                                    small
                                                    color="primary"
                                                    class="ml-3"
                                                    v-if="!isLastDate"
                                                    @click="next"
                                                >
                                                    <v-icon small> mdi-chevron-right </v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="2"> </v-col>
                                        </v-row>
                                    </v-sheet>
                                </v-col>
                            </v-row>
                            <v-row
                                align="center"
                                justify="center"
                                class="white--text"
                            >
                                <v-col
                                    cols="auto"
                                    class="text-h5"
                                > Group: </v-col>
                                <v-col
                                    cols="auto"
                                    id="help_group_name"
                                >
                                    <v-text-field
                                        dark
                                        class="centered-input group-name-input"
                                        single-line
                                        :hint="timezoneFromSetting(group.settings)"
                                        persistent-hint
                                        v-model="group.name"
                                        label="Group Name"
                                        :error="groupErrors.length"
                                        :error-messages="groupErrors && groupErrors.name ? groupErrors.name : null
                                            "
                                        @change="updateGroup(group)"
                                    >
                                        <template v-slot:append>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        id="help_group_config"
                                                        depressed
                                                        tile
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        :color="group.settings && group.settings.length
                                                            ? 'warning'
                                                            : 'info'
                                                            "
                                                        class="ma-0"
                                                        @click="configureGroup(group)"
                                                    >
                                                        <v-icon>mdi-cog</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Configure Entire Location</span>
                                            </v-tooltip>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        id="help_group_embed"
                                                        depressed
                                                        tile
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        color="success"
                                                        class="ma-0"
                                                        @click="copyEmbedCode(group)"
                                                    >
                                                        <v-icon>mdi-xml</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Copy Embed Code</span>
                                            </v-tooltip>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        id="help_group_json"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        depressed
                                                        tile
                                                        color="success"
                                                        class="ma-0"
                                                        @click="copyGroupJsonUrl(group.uid)"
                                                    >
                                                        <v-icon>mdi-code-json</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Copy API Url</span>
                                            </v-tooltip>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row
                                align="center"
                                justify="center"
                                class="white--text"
                            >
                                <v-col cols="auto">
                                    <v-btn
                                        id="help_add_screen"
                                        color="success"
                                        @click="addScreen(group)"
                                    >
                                        <v-icon left>mdi-plus</v-icon> Add Screen to {{ group.name }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row
                                v-if="group.screens && group.screens.length"
                                class="px-3"
                            >
                                <v-col
                                    class="pa-0"
                                    :cols="calcolsValue"
                                    v-for="(screen, screen_index) in group.screens"
                                    v-bind:key="screen.id"
                                >
                                    <v-sheet
                                        :height="82 + (50 * intervalCount)"
                                        elevation="3"
                                    >
                                        <v-calendar
                                            :ref="'calendar_' + screen.id"
                                            v-model="focus"
                                            color="primary"
                                            type="category"
                                            category-show-all
                                            :first-interval="firstInterval"
                                            :interval-minutes="60"
                                            :interval-count="intervalCount"
                                            :day-format="null"
                                            :event-overlap-mode="columns ? 'column' : 'stack'"
                                            :categories="[screen.id.toString()]"
                                            :events="screenGames(group_index, screen, screen_index)"
                                            :event-color="getGameColor"
                                            @mouseup:event="favoriteGame"
                                        >
                                            <template v-slot:category="{ category }">
                                                <v-row
                                                    align="center"
                                                    class="px-2"
                                                >
                                                    <v-col>
                                                        <v-text-field
                                                            id="help_screen_name"
                                                            class="centered-input screen-name-input"
                                                            :hint="timezoneFromSetting(screen.settings)"
                                                            persistent-hint
                                                            single-line
                                                            :value="categoryNameFromScreenID(
                                                                category,
                                                                group.screens
                                                            )
                                                                "
                                                            label="Screen Name"
                                                            @change="(name) =>
                                                                updateScreen(
                                                                    category,
                                                                    group.screens,
                                                                    name
                                                                )
                                                                "
                                                        >
                                                            <template v-slot:append>
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn
                                                                            id="help_screen_config"
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            depressed
                                                                            tile
                                                                            :color="screenConfigButtonColor(
                                                                                category,
                                                                                group.screens
                                                                            )
                                                                                "
                                                                            class="ma-0"
                                                                            @click="
                                                                                configureScreen(
                                                                                    category,
                                                                                    group.screens
                                                                                )
                                                                                "
                                                                        >
                                                                            <v-icon>mdi-cog</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>Configure Screen</span>
                                                                </v-tooltip>
                                                            </template>
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        cols="auto"
                                                        class="d-flex align-center"
                                                    >
                                                        <v-btn-toggle
                                                            id="help_views"
                                                            dense
                                                            v-model="view"
                                                            mandatory
                                                            color="blue"
                                                            shaped
                                                            class="pr-2"
                                                        >
                                                            <v-btn
                                                                value="schedule"
                                                                @click="columns = false"
                                                                id="help_views_scheduled"
                                                            >
                                                                Scheduled
                                                            </v-btn>

                                                            <v-btn
                                                                value="all"
                                                                @click="columns = true"
                                                                id="help_views_all"
                                                            >
                                                                All
                                                            </v-btn>

                                                            <v-btn
                                                                value="suggest"
                                                                @click="/*columns = false*/"
                                                                id="help_views_suggested"
                                                            >
                                                                Suggested
                                                            </v-btn>
                                                        </v-btn-toggle>
                                                        <v-btn-toggle
                                                            id="help_overlap"
                                                            dense
                                                            v-model="columns"
                                                            color="blue"
                                                            shaped
                                                        >
                                                            <v-btn
                                                                :value="false"
                                                                id="help_overlap_on"
                                                            >
                                                                <v-icon> mdi-arrange-send-backward </v-icon>
                                                            </v-btn>

                                                            <v-btn
                                                                :value="true"
                                                                id="help_overlap_off"
                                                            >
                                                                <v-icon> mdi-view-column </v-icon>
                                                            </v-btn>
                                                        </v-btn-toggle>
                                                    </v-col>
                                                </v-row>
                                            </template>
                                            <template v-slot:day-label-header>
                                                <div></div>
                                            </template>
                                            <template v-slot:event="{ event, eventParsed }">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <!-- CALENDAR EVENT -->
                                                        <div
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            :class="getGameTextColor(event) + '--text pl-1 h-100'"
                                                            style="overflow:hidden;"
                                                        >
                                                            <template v-if="emojiEnabled">
                                                                <v-icon
                                                                    id="help_game_icon"
                                                                    v-if="event.preferred"
                                                                    dark
                                                                >
                                                                    mdi-check-all
                                                                </v-icon>
                                                                <v-icon
                                                                    id="help_game_icon"
                                                                    v-else-if="event.favorite"
                                                                    dark
                                                                >
                                                                    mdi-check
                                                                </v-icon>

                                                                {{
                                                                    event.sportEmoji
                                                                }}

                                                            </template>
                                                            <template v-else>
                                                                <v-icon
                                                                    id="help_game_icon"
                                                                    v-if="event.preferred"
                                                                    dark
                                                                >
                                                                    mdi-check-all
                                                                </v-icon>
                                                                <v-icon
                                                                    id="help_game_icon"
                                                                    v-else-if="event.favorite"
                                                                    dark
                                                                >
                                                                    mdi-check
                                                                </v-icon>

                                                                {{ event.sportEmoji }}

                                                            </template>

                                                            {{ event.leagueTitle }}
                                                            <template v-if="debug">
                                                                <br />
                                                                {{ event.id }}
                                                            </template>
                                                            <br />
                                                            {{ event.name }}
                                                            <br />
                                                            {{ getDisplayTime(eventParsed) }}
                                                            <br />
                                                            <hype-chip :game="event" />
                                                            <br />
                                                            <template v-if="debug">
                                                                Debug: {{ event.debug }}
                                                            </template>
                                                        </div>
                                                    </template>
                                                    <!-- TOOLTIP -->
                                                    <v-card
                                                        :color="getGameColor(event)"
                                                        :class="getGameTextColor(event) + '--text pa-2'"
                                                        style="overflow:hidden;"
                                                    >
                                                        {{
                                                            event.sportEmoji
                                                        }}
                                                        {{ event.leagueTitle }}
                                                        <template v-if="debug">
                                                            <br />
                                                            {{ event.id }}
                                                        </template>
                                                        <br />
                                                        {{ event.name }}
                                                        <br />
                                                        {{ getDisplayTime(eventParsed) }}
                                                        <br />
                                                        <hype-chip :game="event" />
                                                        <br />
                                                        <template v-if="debug">
                                                            Debug: {{ event.debug }}
                                                        </template>
                                                    </v-card>
                                                </v-tooltip>
                                            </template>
                                        </v-calendar>
                                    </v-sheet>
                                </v-col>
                            </v-row>

                            <v-row v-else>
                                <v-col class="text-center text-h5 white--text">
                                    No screens added to location "{{ group.name }}." Please

                                    <v-btn
                                        color="success"
                                        @click="addScreen(group)"
                                    >
                                        <v-icon left>mdi-plus</v-icon> Add a Screen
                                    </v-btn>
                                </v-col>
                            </v-row>

                        </v-card>
                        <v-row>
                            <v-spacer />
                            <v-col cols="auto">
                                <v-btn
                                    large
                                    color="success"
                                    @click="addGroupDialog = true"
                                >
                                    <v-icon left>mdi-plus</v-icon> Create New Location
                                </v-btn>
                            </v-col>
                            <v-spacer />
                        </v-row>
                    </div>
                    <div v-if="!loading && !!!groups.length">
                        <v-row>
                            <v-col class="text-center text-h5">
                                No screen group has been added yet. Please
                                <v-btn
                                    large
                                    color="success"
                                    @click="addGroupDialog = true"
                                >
                                    <v-icon left>mdi-plus</v-icon> Add a Location
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>

            <!-- <print /> -->


            <v-dialog
                v-model="addGroupDialog"
                max-width="560"
            >
                <add-group-modal @done="
                    resetData();
                scrollToBottom();
                " />
            </v-dialog>

            <v-dialog
                v-model="addScreenDialog"
                max-width="560"
            >
                <add-screen-modal
                    :group="currentGroup"
                    @done="resetData()"
                />
            </v-dialog>

            <v-dialog
                max-width="800"
                v-model="configureDialog"
                scrollable
                persistent
            >
                <configure-modal
                    :object="currentObject"
                    :type="currentType"
                    @delete="deleteObject(type, object)"
                    @done="resetData()"
                />
            </v-dialog>

            <v-dialog
                v-model="priorityDialog"
                max-width="800"
                @keydown.esc="cancelPriority"
            >
                <v-card>
                    <v-toolbar
                        dark
                        dense
                        flat
                    >
                        <v-toolbar-title class="white--text">Choose Showing Priority</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="pa-4 text-center">
                        These showings overlap. Please select your priority showing.
                        <br />
                        Preferred showings will be played in entirety, inturrupting other
                        marked showings.
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    color="primary"
                                    v-bind="attrs"
                                    v-on="on"
                                >mdi-help-circle</v-icon>
                            </template>
                            <v-card color="primary">
                                <v-card-text class="white--text">
                                    If a preferred showing ends after another marked showing,
                                    the preferred showing will play to the end, then switch to the
                                    non-preferred marked showing.
                                    <br /><br />
                                    If a preferred showing starts before another marked showing
                                    ends, the perferred showing will inturrupt the marked
                                    showing once it begins.
                                    <br />
                                    <br />
                                    Generally, you will want to choose the showing that starts
                                    first as priority, so the end of that showing is seen.
                                </v-card-text>
                            </v-card>
                        </v-tooltip>
                    </v-card-text>
                    <v-row class="mx-3">
                        <v-col v-if="favoritedGame">
                            <div class="text-center text-h5">To be preferred</div>
                            <v-card
                                @click.native="agreePriority(favoritedGame.id)"
                                :color="getGameColor(favoritedGame)"
                                :class="getGameTextColor(favoritedGame) + '--text pa-2'"
                            >
                                <v-icon
                                    :color="getGameTextColor(favoritedGame)"
                                    small
                                >
                                    {{ favoritedGame.sportEmoji }}
                                </v-icon>
                                {{ favoritedGame.leagueTitle }}
                                <br />
                                {{ favoritedGame.name }}
                                <br />
                                {{ getDisplayTimePlain(favoritedGame) }}
                            </v-card>
                        </v-col>
                        <v-col v-if="alreadyFavorited">
                            <div class="text-center text-h5">Already favorited</div>
                            <v-card
                                @click.native="agreePriority(alreadyFavorited.id)"
                                :color="getGameColor(alreadyFavorited)"
                                :class="getGameTextColor(alreadyFavorited) + '--text pa-2'"
                            >
                                <v-icon
                                    :color="getGameTextColor(alreadyFavorited)"
                                    small
                                >
                                    {{ alreadyFavorited.sportEmoji }}
                                </v-icon>
                                {{ alreadyFavorited.leagueTitle }}
                                <br />
                                {{ alreadyFavorited.name }}
                                <br />
                                {{ getDisplayTimePlain(alreadyFavorited) }}
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-card-actions class="pt-0">
                        <v-spacer></v-spacer>
                        <v-btn
                            color="darkGray"
                            text
                            @click.native="cancelPriority"
                        >
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
                v-model="embedModal"
                max-width="800"
            >
                <embed-modal @done="embedModal = false" />
            </v-dialog>
            <help
                ref="help"
                @action="helpAction"
            />
        </v-container>
    </div>
</template>

<script>
import LogoBar from "../components/Home/LogoBar";

import ConfigureModal from "../components/Scheduler/ConfigureModal";

import AddGroupModal from "../components/Scheduler/AddGroupModal";

import AddScreenModal from "../components/Scheduler/AddScreenModal";

import EmbedModal from "../components/Scheduler/EmbedModal";

import Help from "../components/Scheduler/Help";

import Print from "../components/Scheduler/Print";

import HypeChip from "../components/Scheduler/HypeChip";

import {
    GET_GROUPS,
    GET_SCREEN_GAMES,
    GET_OPTIONS,
    UPDATE_GROUP,
    UPDATE_SCREEN,
    POST_SELECTED_OPTION,
    FAVORITE_GAME,
    PREFER_GAME,
    UNFAVORITE_GAME,
    GET_SPORTS
} from "../store/actions.type";



export default {
    name: "Planner",
    components: {
        LogoBar,
        ConfigureModal,
        AddGroupModal,
        AddScreenModal,
        EmbedModal,
        Help,
        Print,
        HypeChip,
    },
    data: () => ({
        debug: false,
        loading: true,
        gameColors: false,
        //Config
        addGroupDialog: false,
        configureDialog: false,
        addScreenDialog: false,
        embedModal: false,
        currentGroup: null,
        currentScreen: null,
        currentType: null,
        view: "suggest",
        emojiEnabled: true,
        //Calendar
        calcols: 1,
        columns: true,
        focus: null,
        //Priority
        priorityDialog: false,
        resolve: null,
        reject: null,
        favoritedGame: null,
        alreadyFavorited: null,
        chosenGame: null,
        alreadyUsedGames: [],
    }),
    async mounted() {
        this.focus = this.todaysDate;
        await this.$store.dispatch(GET_GROUPS, this.focus);
        await this.$store.dispatch(GET_OPTIONS);
        await this.$store.dispatch(GET_SPORTS);
        await this.updateGames();
        this.loading = false;
    },
    computed: {
        groupErrors() {
            return this.$store.getters.getErrors("groups");
        },
        // loading() {
        //     return this.$store.getters.getGroupsLoading;
        // },
        groups() {
            return this.$store.getters.getGroups;
        },
        allGames() {
            return this.$store.getters.getScreenGames;
        },
        todaysDate() {
            return moment().format("YYYY-MM-DD");
        },
        isTodaysDate() {
            return this.focus == this.todaysDate;
        },
        isLastDate() {
            return moment(this.focus).isSame(moment(this.todaysDate).add(7, "day"));
        },
        currentBusinessHours() {
            return this.$store.getters.getCurrentSelectedOptions('hours');
        },
        firstInterval() {
            if (this.currentBusinessHours) {
                return parseInt(this.currentBusinessHours.split(":")[0]);
            }
            //Default 9AM-9PM
            return 4;
        },
        intervalCount() {
            if (this.currentBusinessHours) {
                return parseInt(this.currentBusinessHours.split("-")[1].split(":")[0]) - parseInt(this.currentBusinessHours.split(":")[0]);
            }
            //Default 9AM-9PM
            return 20;
        },
        currentDate() {
            return moment(this.focus).format("dddd, MMMM Do YYYY");
        },
        currentObject() {
            if (this.currentType) {
                return this["current" + this.currentType.capitalize()];
            }
            return null;
        },
        calcolsValue() {
            switch (this.calcols) {
                case 1:
                    return 12;
                case 2:
                    return 6;
                case 3:
                    return 4;
                case 4:
                    return 3;
                case 5:
                    return 2;
            }
        },
        firstGame() {
            return this.allGames[this.screens[0].zip_dma_id][0];
        },
        sports() {
            return this.$store.getters.getCurrentSports;
        },
        leagues() {
            return this.$store.getters.getCurrentLeagues;
        },
    },
    methods: {
        //Help
        helpAction(type) {
            switch (type) {
                case 'scheduled':
                    this.view = "schedule";
                    this.columns = false;
                    break;
                case 'all':
                    this.view = "all";
                    this.columns = true;
                    break;
                case 'suggested':
                    this.view = "suggest";
                    this.columns = false;
                    break;

                case 'overlap_on':
                    this.columns = false;
                    break;

                case 'favorite_game':
                    this.groups[0].screens[0].favorites.push(this.firstGame.id);
                    break;

                case 'prefer_game':
                    this.groups[0].screens[0].preferred.push(this.firstGame.id);
                    break;

                case 'clear_favorites':
                    this.groups[0].screens[0].favorites = [];
                    this.groups[0].screens[0].preferred = [];
                    break;

            }
        },

        //Config
        updateGroup(group) {
            this.$store.dispatch(UPDATE_GROUP, group);
        },

        addScreen(group) {
            this.currentGroup = group;
            this.addScreenDialog = true;
        },
        updateScreen(id, screens, name) {
            let screen = null;
            screen = this.searchArrayByKey(id, screens);

            screen.name = name;

            this.$store.dispatch(UPDATE_SCREEN, screen);
        },
        configureGroup(group) {
            this.currentGroup = group;
            this.currentType = "group";
            this.configureDialog = true;
        },
        configureScreen(id, screens) {
            let screen = null;
            screen = this.searchArrayByKey(id, screens);

            this.currentScreen = screen;
            this.currentType = "screen";
            this.configureDialog = true;
        },
        copyGroupJsonUrl(uid) {
            let url = this.appUrl + "/groups/" + uid + "/games";
            navigator.clipboard.writeText(url);
        },
        resetData() {
            this.loading = true;
            this.addGroupDialog = false;
            this.deleteGroupDialog = false;

            this.addScreenDialog = false;
            this.deleteScreenDialog = false;

            this.configureDialog = false;

            this.currentGroup = null;
            this.currentScreen = null;
            this.currentType = null;

            this.updateGames();
        },
        timezoneFromSetting(settings) {
            if (settings) {
                let timezoneIndex = settings.findIndex(
                    (setting) => setting.key === "timezone"
                );
                if (timezoneIndex >= 0) {
                    return settings[timezoneIndex].selectedOptions.split("/")[1].replace("_", " ");
                }
            }
            return "No Timezone Set";
        },
        categoryNameFromScreenID(id, screens) {
            return this.searchArrayByKey(id, screens, "id", "name");
        },
        screenConfigButtonColor(id, screens) {
            let screen = null;
            screen = this.searchArrayByKey(id, screens);
            return screen.settings && screen.settings.length ? "warning" : "info";
        },

        //Start of game selection for display
        //-- Start of fully functional section
        log(...args) {
            if (this.debug) {
                console.log(...args);
            }
        },

        logTable(data) {
            if (this.debug) {
                console.table(data);
            }
        },

        isGameFavoriteOrPreferred(game, screen) {
            return (screen.favorites?.includes(game.id) || screen.preferred?.includes(game.id)) ?? false;
        },

        isGameLeaguePreferred(game, screen) {
            const leaguePriorities = this.getLeaguePriorities(screen);
            return leaguePriorities.includes(game.league_id);
        },

        getLeaguePriorities(screen) {
            if (this.debug) {
                this.log("Prioritized leagues: ", screen.settings.find(setting => setting.key === "leagues")?.selectedOptions);
            }
            if (screen.settings && screen.settings.length) {
                const leaguesSettings = screen.settings.find(setting => setting.key === "leagues");
                if (leaguesSettings && leaguesSettings.selectedOptions) {
                    if (this.debug) {
                        this.log("Prioritized leagues: ", leaguesSettings.selectedOptions);
                    }
                    return leaguesSettings.selectedOptions;
                }
            }
            return [];
        },

        screenGames(group_index, screen, screen_index) {
            this.log("STARTING SCREEN GAMES: ", screen.name);
            this.log("Current view:", this.view);
            if (this.allGames.length === 0) {
                return [];
            }
            if (this.view === "schedule") {
                //Get games that have been PREFERRED, or FAVORITED
                return this.getScheduleViewGames(screen, group_index, screen_index);
            } else if (this.view === "all") {
                //Get ALL games
                return this.getAllViewGames(screen, group_index, screen_index);
            } else if (this.view === "suggest") {
                //Get suggested games
                return this.getSuggestViewGames(screen, group_index, screen_index);
            }
        },

        getScheduleViewGames(screen, group_index, screen_index) {
            const scheduledGames = this.allGames[screen.zip_dma_id].filter(game =>
                (screen.favorites && screen.favorites.includes(game.id)) ||
                (screen.preferred && screen.preferred.includes(game.id))
            );
            return this.formatGames(scheduledGames, screen, group_index, screen_index);
        },

        getAllViewGames(screen, group_index, screen_index) {
            const games = this.allGames[screen.zip_dma_id];
            return this.formatGames(games, screen, group_index, screen_index);
        },
        //-- Start of Needs work section


        getZipDmaGames(zip_dma_id) {
            let zipDmaGames = this.allGames[zip_dma_id];

            return zipDmaGames
                .filter(game => game.showings.length)
                .sort((a, b) => {
                    // First compare by start time
                    const timeA = new Date(a.showings[0].starts_at).getTime();
                    const timeB = new Date(b.showings[0].starts_at).getTime();
                    if (timeA !== timeB) return timeA - timeB;

                    // Then by league rank (handle undefined ranks)
                    const rankA = a.league?.rank ?? Infinity;
                    const rankB = b.league?.rank ?? Infinity;
                    if (rankA !== rankB) return rankA - rankB;

                    // Finally by game ID
                    return a.id - b.id;
                });
        },

        getSuggestViewGames(screen, group_index, screen_index) {
            this.log("Starting suggest view game selection");

            if (screen_index == 0) {
                this.$options.alreadyAdded = [];
            }

            const timeSlots = this.createTimeSlots();
            this.log(`Created ${timeSlots.length} time slots`);

            const leaguePriorities = this.getLeaguePriorities(screen);
            this.log("League priorities:", leaguePriorities);

            this.fillTimeSlots(timeSlots, screen, leaguePriorities);

            this.logTimeSlots(timeSlots, screen);

            const scheduledGames = this.timeSlotsToScheduledGames(timeSlots);
            this.log(`Final scheduled games count: ${scheduledGames.length}`);

            this.updateAlreadyAddedGames(scheduledGames);

            return this.formatGames(scheduledGames, screen, group_index, screen_index);
        },

        logTimeSlots(timeSlots, screen) {
            this.log("Time slots after filling:");
            const table = timeSlots.map(slot => ({
                Time: slot.start.tz('America/New_York').format('HH:mm'),
                Game: slot.game ? `${slot.game.id} (${this.getGamePriorityLabel(slot.game, screen)})` : 'Empty',
                League: slot.game ? slot.game.league_id : '-'
            }));
            this.logTable(table);
        },

        getGamePriorityLabel(game, screen) {
            if ((screen.favorites && screen.favorites.includes(game.id)) ||
                (screen.preferred && screen.preferred.includes(game.id))) {
                return 'Favorite';
            }
            const leaguePriority = this.getLeaguePriorities(screen).indexOf(game.league_id);
            if (leaguePriority !== -1) {
                return `League ${leaguePriority + 1}`;
            }
            return `Rank ${game.league.rank || 'N/A'}`;
        },

        createTimeSlots() {
            const timeSlots = [];
            const selectedDate = moment.tz(this.focus, "YYYY-MM-DD", "America/Los_Angeles");
            const dayStart = selectedDate.clone().startOf('day');
            const dayEnd = selectedDate.clone().endOf('day');
            let currentTime = dayStart.clone();

            while (currentTime.isBefore(dayEnd)) {
                timeSlots.push({
                    start: currentTime.clone(),
                    end: currentTime.clone().add(15, 'minutes'),
                    game: null
                });
                currentTime.add(15, 'minutes');
            }

            return timeSlots;
        },


        fillTimeSlots(timeSlots, screen, leaguePriorities) {
            this.log("Starting to fill time slots");
            timeSlots.forEach((slot, index) => {
                this.log(`Processing slot ${index}: ${slot.start.format()} - ${slot.end.format()}`);
                const topPriorityGame = this.findTopPriorityGame(slot, screen, leaguePriorities);
                if (topPriorityGame && this.shouldAddGame(topPriorityGame, slot, screen, leaguePriorities)) {
                    this.addGameToTimeSlots(topPriorityGame, timeSlots, index);
                    this.log(`Added game ${topPriorityGame.id} to slot ${index}`);
                } else {
                    this.log(`No new game added to slot ${index}`);
                }
            });
        },

        findTopPriorityGame(slot, screen, leaguePriorities) {
            const zipDmaGames = this.getZipDmaGames(screen.zip_dma_id);
            const suitableGames = zipDmaGames.filter(game => this.isGameSuitableForSlot(game, slot));

            if (suitableGames.length === 0) return null;

            suitableGames.sort((a, b) => this.compareGamePriorities(a, b, screen, leaguePriorities));

            return suitableGames[0];
        },

        shouldAddGame(game, slot, screen, leaguePriorities) {
            if (!slot.game) return true;  // If slot is empty, always add the game

            // Get priorities for comparison
            const newGamePriority = this.getGamePriority(game, screen, leaguePriorities);
            const existingGamePriority = this.getGamePriority(slot.game, screen, leaguePriorities);

            // If new game is a favorite/preferred or in league priority list (priority 1 or 2),
            // allow it to replace existing game
            if (newGamePriority.priority <= 2) {
                return this.compareGamePriorities(game, slot.game, screen, leaguePriorities) < 0;
            }

            // If existing game is already a favorite/preferred or in league priority list,
            // don't replace it
            if (existingGamePriority.priority <= 2) {
                return false;
            }

            // For regular games (priority 3), only replace if the league rank difference is significant
            if (newGamePriority.priority === 3 && existingGamePriority.priority === 3) {
                const newGameRank = game.league.rank || Infinity;
                const existingGameRank = slot.game.league.rank || Infinity;

                // Only replace if new game's rank is at least 10 points better
                if (newGameRank >= existingGameRank - 10) {
                    return false;
                }
            }

            // For all other cases, use the standard priority comparison
            return this.compareGamePriorities(game, slot.game, screen, leaguePriorities) < 0;
        },

        isGameSuitableForSlot(game, slot) {
            const gameStart = moment.tz(game.showings[0].starts_at, "UTC").tz("America/Los_Angeles");
            const gameEnd = moment.tz(game.showings[0].ends_at, "UTC").tz("America/Los_Angeles");
            return gameStart.isSameOrBefore(slot.end) && gameEnd.isAfter(slot.start);
        },

        addGameToTimeSlots(game, timeSlots, startIndex) {
            const gameStart = moment.tz(game.showings[0].starts_at, "UTC").tz("America/Los_Angeles");
            const gameEnd = moment.tz(game.showings[0].ends_at, "UTC").tz("America/Los_Angeles");

            for (let i = startIndex; i < timeSlots.length; i++) {
                const slot = timeSlots[i];
                if (gameStart.isSameOrBefore(slot.end) && gameEnd.isAfter(slot.start)) {
                    slot.game = game;
                } else if (slot.start.isSameOrAfter(gameEnd)) {
                    break; // We've passed the end of the game
                }
            }
        },

        getGamePriority(game, screen, leaguePriorities) {
            if ((screen.favorites && screen.favorites.includes(game.id)) ||
                (screen.preferred && screen.preferred.includes(game.id))) {
                return { priority: 1, subPriority: 0 }; // Highest priority
            }

            const leaguePriority = leaguePriorities.indexOf(game.league_id);
            if (leaguePriority !== -1) {
                return { priority: 2, subPriority: leaguePriority }; // Priority based on league order
            }

            // Get the game end time as a timestamp for comparison
            const gameEnd = moment.tz(game.showings[0].ends_at, "UTC").tz("America/Los_Angeles").valueOf();

            // Use league rank as a base for subPriority, but subtract a normalized end time factor
            // We divide by a large number to make sure this doesn't overwhelm the league rank
            let subPriority = (game.league.rank || Infinity) - (gameEnd / 10000000);

            let basePriority = {
                priority: 3,
                subPriority: subPriority
            };

            // Decrease priority for already added games
            if (this.$options.alreadyAdded.includes(game.id)) {
                basePriority.priority += 100;
            }

            return basePriority;
        },

        compareGamePriorities(gameA, gameB, screen, leaguePriorities) {
            const priorityA = this.getGamePriority(gameA, screen, leaguePriorities);
            const priorityB = this.getGamePriority(gameB, screen, leaguePriorities);

            if (priorityA.priority !== priorityB.priority) {
                return priorityA.priority - priorityB.priority;
            }

            return priorityA.subPriority - priorityB.subPriority;
        },

        timeSlotsToScheduledGames(timeSlots) {
            const scheduledGamesSet = new Set();
            timeSlots.forEach(slot => {
                if (slot.game) {
                    scheduledGamesSet.add(slot.game);
                }
            });
            return Array.from(scheduledGamesSet);
        },

        updateAlreadyAddedGames(scheduledGames) {
            scheduledGames.forEach(game => {
                if (!this.$options.alreadyAdded.includes(game.id)) {
                    this.$options.alreadyAdded.push(game.id);
                }
            });
        },



        formatGames(games, screen, group_index, screen_index) {
            if (!games.length) {
                this.log("No games to format");
                return [];
            }

            return games
                .filter(game => {
                    if (!game.showings || !game.showings.length) {
                        this.log(`Skipping game ${game.id || 'unknown'} - No showings found`);
                        return false;
                    }
                    return true;
                })
                .map(game => this.getCalendarFormattedGame(
                    game,
                    screen.favorites && screen.favorites.includes(game.id),
                    screen.preferred && screen.preferred.includes(game.id),
                    moment.utc(game.showings[0].starts_at),
                    moment.utc(game.showings[0].ends_at),
                    screen,
                    group_index,
                    screen_index,
                    game.debug ? game.debug : null
                ));
        },

        getGameColor(game) {
            if (game.favorite || game.preferred) return "success";

            if (!this.gameColors) return "primary";

            if (!game.sportId) return "indego";

            return searchArrayByKey(game.sportId, this.sports, 'id').background_color;
        },
        getGameTextColor(game) {
            if (game.favorite || game.preferred) return "white";

            if (!this.gameColors) return "white";

            if (!game.sportId) return "black";

            return searchArrayByKey(game.sportId, this.sports, 'id').text_color;
        },
        getDisplayTime(gameParsed) {
            return (
                moment(gameParsed.start.time, "hh:mm").format("h:mma") +
                "-" +
                moment(gameParsed.end.time, "hh:mm").format("h:mma")
            );
        },
        getDisplayTimePlain(game) {
            return (
                moment(game.start, "hh:mm").format("h:mma") +
                "-" +
                moment(game.end, "hh:mm").format("h:mma")
            );
        },
        updateGames() {
            if (this.debug) {
                console.log("Updating games for date: ", this.focus);
                // this.loading = false;
            }
            this.$store.dispatch(GET_SCREEN_GAMES, this.focus)
                .then(() => {
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        setToday() {
            this.focus = this.todaysDate;
            this.$nextTick(function () {
                this.loading = true;
                this.updateGames();
            });
        },
        prev() {
            this.focus = moment(this.focus).add(-1, "day").format("YYYY-MM-DD");
            this.$nextTick(function () {
                this.loading = true;
                this.updateGames();
            });
        },
        next() {
            this.focus = moment(this.focus).add(1, "day").format("YYYY-MM-DD");
            this.$nextTick(function () {
                this.loading = true;
                this.updateGames();
            });
        },
        rnd(a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a;
        },
        async favoriteGame({ event }) {
            let gameToFavorite = event;
            var screen_id = parseInt(gameToFavorite.category);
            var gameToFavorite_id = parseInt(gameToFavorite.id);
            var date = this.focus;

            var group_index = gameToFavorite.group_index;
            var screen_index = gameToFavorite.screen_index;

            var screen = this.groups[group_index].screens[screen_index];

            var add_favorite = true;

            //If no favorites, just add it (nothing to compare)
            if (!screen.favorites) {
                //console.log("No favorites exist for this screen yet, so add it.");
                await this.$store.dispatch(FAVORITE_GAME, {
                    screen_id,
                    date,
                    game_id: gameToFavorite_id,
                });
                return;
            }

            //If screen has favorites, and it is already favorited, just remove it.
            if (screen.favorites && screen.favorites.indexOf(gameToFavorite_id) >= 0) {
                //console.log("Favorite already exists, remove it.");
                await this.$store.dispatch(FAVORITE_GAME, {
                    screen_id,
                    date,
                    game_id: gameToFavorite_id,
                });
                return;
            }

            //If screen has preferred, and it is already preferred, just remove it.
            if (screen.preferred && screen.preferred.indexOf(gameToFavorite_id) >= 0) {
                //console.log("Favorite already exists, remove it.");
                await this.$store.dispatch(PREFER_GAME, {
                    screen_id,
                    date,
                    game_id: gameToFavorite_id,
                });
                return;
            }

            //Screen has favorites, and it is NOT already favorited, time for some magic.
            this.allGames[screen.zip_dma_id].forEach(async (allGame) => {
                if (allGame.id == gameToFavorite_id) {
                    //Skip allGame thats same as clicked gameToFavorite...
                    return;
                }

                //Check if this all game is already a favorite or preferred
                if (screen.favorites && screen.favorites.indexOf(allGame.id) >= 0 || screen.preferred && screen.preferred.indexOf(allGame.id) >= 0) {
                    //If so we have to do some compares
                    const game_start = moment.tz(allGame.showings[0].starts_at, "UTC");
                    const game_end = moment.tz(allGame.showings[0].ends_at, "UTC");

                    const favorite_start = moment(gameToFavorite.start);
                    const favorite_end = moment(gameToFavorite.end);

                    //Check if same start/end
                    if (
                        favorite_start.isSame(game_end) || favorite_end.isSame(game_start)
                    ) {
                        console.log("Is same start = end, continue");
                        return;
                    }

                    //Check if within 5 minutes, if so force removal of other games
                    var startDifferenceDuration = moment.duration(game_start.diff(favorite_start));
                    var startDifferenceDurationInMinutes = Math.abs(startDifferenceDuration.asMinutes());

                    var endDifferenceDuration = moment.duration(game_end.diff(favorite_end));
                    var endDifferenceDurationInMinutes = Math.abs(endDifferenceDuration.asMinutes());

                    if (startDifferenceDurationInMinutes <= 5 && endDifferenceDurationInMinutes <= 5) {
                        //console.log("Start and end time within 5 minutes, remove other");
                        //Remove and add new favorite and finish (TODO: Remove any other overlaps?)
                        await this.$store.dispatch(UNFAVORITE_GAME, {
                            screen_id,
                            date,
                            game_id: allGame.id,
                        });

                        //Wait for nextTick to finish
                        await this.$nextTick();

                        return;
                    }

                    //Check which starts first

                    if (favorite_start.isBefore(game_start)) {
                        //Favorite Starts First
                        //Does favorite end before showing? ignore
                        if (favorite_end.isBefore(game_start)) {
                            return;
                        }
                    } else {
                        //Showing Starts First
                        //Does showing end before favorite starts? ignore
                        if (game_end.isBefore(favorite_start)) {
                            return;
                        }
                    }

                    //Favorite and showing overlap
                    //console.log("Showings overlap after 30 minutes start.");
                    this.favoritedGame = gameToFavorite;

                    this.alreadyFavorited = this.getCalendarFormattedGame(allGame, true, false, game_start, game_end, screen, group_index, screen_index, "favorited");

                    //DIALOG HERE
                    if (await this.openPriorityDialog()) {
                        //Already preferred
                        if (screen.preferred && screen.preferred.indexOf(this.chosenGame) >= 0) {
                            return;
                        }
                        //prefer chosen showing
                        await this.$store.dispatch(PREFER_GAME, {
                            screen_id,
                            date,
                            game_id: this.chosenGame,
                        });
                    } else {
                        //console.log("User Cancelled.");
                        return;
                    }

                    this.favoritedGame = null;
                    this.alreadyFavorited = null;
                    this.chosenGame = null;
                    return;
                } // End of checking if showing in loop is already favorited.
            }); // End Showing For Loop

            if (add_favorite) {
                await this.$store.dispatch(FAVORITE_GAME, {
                    screen_id,
                    date,
                    game_id: gameToFavorite_id,
                });
            }

            //console.log("Finished Loop");
            return;
        },
        //Priority
        openPriorityDialog(title, message, options) {
            this.priorityDialog = true;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        agreePriority(id) {
            this.chosenGame = id;
            this.resolve(true);
            this.priorityDialog = false;
        },
        cancelPriority() {
            this.resolve(false);
            this.priorityDialog = false;
        },
        copyEmbedCode(group) {
            let embedCode = `<iframe src="${this.appUrl}/embed/${group.uid}" frameborder="0" />`;
            navigator.clipboard.writeText(embedCode);
        },
        getCalendarFormattedGame(game, favorite, preferred, start, end, screen, group_index, screen_index, debug) {
            let description = null;

            if (game.description) {
                description = game.description;
            } else {
                description = game.away_team + " @ " + game.home_team;
            }

            let screenTimezone = "America/New_York";
            if (screen.settings && screen.settings.length) {
                screenTimezone = screen.settings.find((setting) => setting.key === "timezone").selectedOptions;
            }

            return {
                id: game.id,
                name: description,
                sportId: game.sport.id,
                sportTitle: game.sport.title,
                sportIcon: game.sport.icon,
                sportEmoji: game.sport.emoji,
                leagueId: game.league_id,
                favorite: favorite,
                preferred: preferred,
                //Update to proper timezone using the screens timezone option
                start: start.tz(screenTimezone).format('YYYY-MM-DDTHH:mm:ss'),
                end: end.tz(screenTimezone).format('YYYY-MM-DDTHH:mm:ss'),
                leagueTitle: game.league.title,
                timed: true,
                category: screen.id.toString(),
                group_index,
                screen_index,
                thuuz_game: game.thuuz_game,
                debug: debug,
            };
        }
    },
    watch: {
        showAllGames: function () {
            let key = "days";
            let options = this.focus;

            //Set day to todays date
            this.$store.dispatch(POST_SELECTED_OPTION, { key, options }).then(() => {
                // this.$gtag.game("api", {
                //   game_category: "options",
                //   game_label: "get_games",
                // });

                //this.$store.dispatch(GET_GAMES);
            });
        },
    },
    created() {
        this.$options.alreadyAdded = [];
    }
};
</script>

<style scoped>
.centered-input>>>input {
    text-align: center;
    font-size: 1.4em;
}

.centered-input>>>.v-messages__message {
    text-align: center;
}

.group-name-input>>>.v-messages__message {
    padding-right: 35%;
}

.screen-name-input>>>.v-messages__message {
    padding-right: 5%;
}

.v-calendar>>>.v-calendar-daily_head-weekday {
    display: none !important;
}

.v-tooltip__content {
    border: 1px solid black !important;
    background: black;
    padding: 2px;
}

@media print {
    .v-tooltip {
        display: none;
    }
}
</style>
