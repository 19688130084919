import { render, staticRenderFns } from "./FavoriteHypeBadge.vue?vue&type=template&id=74cace4c&scoped=true&"
import script from "./FavoriteHypeBadge.vue?vue&type=script&lang=js&"
export * from "./FavoriteHypeBadge.vue?vue&type=script&lang=js&"
import style0 from "./FavoriteHypeBadge.vue?vue&type=style&index=0&id=74cace4c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74cace4c",
  null
  
)

export default component.exports